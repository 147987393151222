import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '../utils/LinkResolver'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import Members from '../components/Members-components/PdpMembers-component'

const AllRE = ({ data }) => {
  if (!data) return null

  const doc = data.prismicCollectionPage.data
  const docs = data.allPrismicPdpMember
  const pageContent = data.prismicCollectionPage
  const { lang, type, url } = pageContent
  const page = pageContent.data || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicMenuLink || {}
  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.title.text} lang={lang} />
      <h1 className="news-title">{doc.title.text} </h1>
      {doc.description.text && (
        <p className="m-3 mb-5 text-center">{doc.description.text}</p>
      )}
      <Members docs={docs} doc={doc} />
    </Layout>
  )
}

export const query = graphql`
  query dpdMembersCollectionPageQuery($lang: String) {
    prismicCollectionPage(
      uid: { eq: "enter-registered-professional-development-programs" }
      lang: { eq: $lang }
    ) {
      alternate_languages {
        uid
        type
        lang
      }
      type
      url
      lang
      _previewable
      data {
        enter_registered_professional_development_programs_page {
          accreditation_agency_label
          accreditation_period_label
          additional_information_label
          country_label
          enter_accreditation_level_label
          higher_education_institution_label
          program_name_label
          view_more_label
          search_by_agency_label
          search_by_country_label
          search_by_level_label
          search_by_university_label
          search_label
        }
        title {
          text
        }
        description {
          text
          raw
        }
        links {
          background_image {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              width: 321
              height: 256
            )
          }
          link {
            url
          }
          link_title {
            text
          }
        }
      }
    }
    allPrismicPdpMember(
      filter: { lang: { eq: $lang } }
      sort: { order: ASC, fields: data___program_name }
    ) {
      nodes {
        _previewable
        alternate_languages {
          id
          lang
        }
        id
        lang
        tags
        uid
        data {
          program_name
          program_name__local_language
          program_name_label
          program_name_local_lang_label
          accreditation_details {
            accreditation_details_label
            accreditation_from(formatString: "DD/MM/YYYY")
            accreditation_until(formatString: "DD/MM/YYYY")
            period_label
            until_label
            accreditation_agency_label
            accreditation_agency
          }
          enter_accreditation_level
          hei_information {
            city
            city_label
            country
            country_label
            hei_information_label
            hei_name
            hei_name__local_language
            hei_name_label
            hei_name_local_lang_label
            street
            street_label
            website {
              url
              target
            }
            website_label
          }
          program_information {
            area
            area_label
            certificae_label
            certificate
            ects
            ects_label
            program_information_label
            website {
              target
              url
            }
            website_label
          }
          enter_acreditation_level_label
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`

export default withPrismicPreview(AllRE, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
