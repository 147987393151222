import React from 'react'
import { Link } from 'gatsby'
import { Button, Row, Col, Container, Table } from 'react-bootstrap'
import { useState } from 'react'

const Members = ({ docs, doc }) => {
  const [searchCountry, setSearchCountry] = useState('')
  const [searchUniversity, setSearchUniversity] = useState('')
  const [searchLevel, setSearchLevel] = useState('')
  const [searchAgency, setSearchAgency] = useState('')

  return (
    <Container fluid>
      <Row className="mt-2 mb-5 mx-3 shadow p-3 bg-body rounded">
        <h5>
          {
            doc.enter_registered_professional_development_programs_page[0]
              .search_label
          }
          :
        </h5>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="filterCountry">
            by{' '}
            {
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_country_label
            }
          </label>
          <input
            type="text"
            className="input-section-filter"
            id="filterCountry"
            placeholder={
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_country_label
            }
            onChange={(event) => {
              setSearchCountry(event.target.value)
            }}
          />
        </Col>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="filterUniversity">
            by{' '}
            {
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_university_label
            }
          </label>
          <input
            type="text"
            className="input-section-filter"
            id="filterUniversity"
            placeholder={
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_university_label
            }
            onChange={(event) => {
              setSearchUniversity(event.target.value)
            }}
          />
        </Col>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="filterLevel">
            {' '}
            {
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_level_label
            }
          </label>
          <select
            name="filterLevel"
            id="filterLevel"
            onChange={(event) => {
              setSearchLevel(event.target.value)
            }}
          >
            <option value="">
              {
                doc.enter_registered_professional_development_programs_page[0]
                  .search_by_level_label
              }
            </option>
            <option value="1">Level 1</option>
            <option value="2">Level 2</option>
            <option value="3">Level 3</option>
          </select>
        </Col>
        <Col className="input-section-filter_wrapper" xs={12} md={3}>
          <label className="invisible" htmlFor="agency">
            {
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_agency_label
            }
          </label>
          <input
            type="text"
            className="input-section-filter"
            id="agency"
            placeholder={
              doc.enter_registered_professional_development_programs_page[0]
                .search_by_agency_label
            }
            onChange={(event) => {
              setSearchAgency(event.target.value)
            }}
          />
        </Col>
      </Row>
      <Row xs={1} className="mt-2 mb-5 mx-3">
        <Col>
          <Table bordered hover responsive>
            <thead>
              <tr className="bg-secondary bg-gradient text-white">
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .program_name_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .higher_education_institution_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .enter_accreditation_level_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .accreditation_period_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .country_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .accreditation_agency_label
                  }
                </th>
                <th className="align-middle text-center">
                  {
                    doc
                      .enter_registered_professional_development_programs_page[0]
                      .additional_information_label
                  }
                </th>
              </tr>
            </thead>
            <tbody>
              {docs.nodes
                .filter(
                  (val) => {
                    return searchCountry === '' &&
                      searchUniversity === '' &&
                      searchLevel === '' &&
                      searchAgency === ''
                      ? val
                      : val.data.hei_information[0].country
                          .toLowerCase()
                          .includes(searchCountry.toLowerCase()) &&
                        val.data.hei_information[0].hei_name
                          .toLowerCase()
                          .includes(searchUniversity.toLowerCase()) &&
                        val.data.enter_accreditation_level
                          .toLowerCase()
                          .includes(searchLevel.toLowerCase()) &&
                        val.data.accreditation_details[0].accreditation_agency
                          .toLowerCase()
                          .includes(searchAgency.toLowerCase())
                      ? val
                      : null
                  },
                )
                .map((member, key) => {
                  return (
                    <tr key={key}>
                      <td className="align-middle text-center">
                        {member.data.program_name}
                      </td>
                      <td className="align-middle text-center">
                        {member.data.hei_information[0].hei_name}
                      </td>
                      <td className="align-middle text-center">
                        Level {member.data.enter_accreditation_level}
                      </td>
                      <td className="align-middle text-center">
                        {
                          member.data.accreditation_details[0]
                            .accreditation_from
                        }
                        <br />
                        {member.data.accreditation_details[0].until_label}
                        <br />
                        {
                          member.data.accreditation_details[0]
                            .accreditation_until
                        }
                      </td>
                      <td className="align-middle text-center">
                        {member.data.hei_information[0].country}
                      </td>
                      <td className="align-middle text-center">
                        {
                          member.data.accreditation_details[0]
                            .accreditation_agency
                        }
                      </td>
                      <td className="align-middle text-center">
                        <Link to={member.uid}>
                          <Button
                            style={{ width: '100%' }}
                            variant="outline-primary"
                          >
                            {
                              doc
                                .enter_registered_professional_development_programs_page[0]
                                .view_more_label
                            }
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
export default Members
